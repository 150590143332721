import { Box, Center, Flex, Spinner, Square, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { SpriteAnimator } from "react-sprite-animator";
import { APIClient } from "../../api";
import { useAppContext } from "../../contexts";
import { roundDown } from "../../utils";
import EnergyBar from "./energy";
import ScoreTag from "./score";
import { useHistory } from "react-router-dom";
import { BoostIcon, MultipleIcon } from "../../theme/components/icon";
import AutoplayButton from "./autoplay";
import toast from "react-hot-toast";
import { useDuckImage } from "hooks/useDuckImage";
// import ReactCSSTransitionGroup from 'react-transition-group'; // ES6
const animateMetaData = {
  1: {
    idle: {
      name: "idle",
      frames: 44,
    },
    running: {
      name: "running",
      frames: 26,
    },
  },
  2: {},
};

const TelegramUserData = () => {
  const {
    userToken,
    userInfo,
    currentPoint,
    setCurrentPoint,
    isLoadingUser,
    currentEnergy,
    setCurrentEnergy,
    updateUserInfo,
    stableSize,
    fetchUser,
    liveEnergy,
  } = useAppContext();
  const currentEnergyRef = useRef(liveEnergy);
  useEffect(() => {
    currentEnergyRef.current = liveEnergy;
  }, [liveEnergy]);

  const [animation, setAnimation] = useState(animateMetaData[1]?.idle);
  const [isReady, setIsReady] = useState(true);
  const history = useHistory();
  const animationTimeoutRef = useRef(null);

  const [isRunning, setIsRunning] = useState(false);
  const [isJump, setIsJump] = useState(false);

  const handleJump = () => {
    const isJump = Math.random() < 0.4; // 40% chance of winning

    if (isJump) {
      setIsJump(true);
    } else {
      setIsJump(false);
    }
  };

  const [animations, setAnimations] = useState([]);

  useEffect(() => {
    const handleClick = (event) => {
      const myDuck = document.getElementById("my-duck");

      if (myDuck?.contains(event.target)) {
        // if (isAutoPlayMode.current) {
        //   setIsAutoPlayMode(false);
        // }
        console.log("Clicked inside the image");
        const newAnimation = {
          id: Date.now(),
          x: event.clientX,
          y: event.clientY,
        };
        if (currentEnergyRef.current > 1) {
          setAnimations((prevAnimations) => [...prevAnimations, newAnimation]);
          setTimeout(() => {
            setAnimations((prevAnimations) =>
              prevAnimations.filter(
                (animation) => animation.id !== newAnimation.id
              )
            );
          }, 1000);
        }

        return;
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const { duckUrl, loadDuckImage } = useDuckImage(userInfo);

  const isAutoPlayMode = useRef(false);

  const setIsAutoPlayMode = (v) => {
    isAutoPlayMode.current = v;
  };

  return (
    <>
      <Box
        pt="2vh"
        w="100%"
        h="100%"
        display="flex"
        flexDirection="column"
        bg="#FFFDA4"
      >
        <ScoreTag currentPoint={currentPoint} />

        <EnergyBar />
        <Flex w="full" justifyContent="end" flexDirection="column">
          <SpriteAnimator
            width={430}
            height={186}
            fps={24}
            scale={1}
            frameCount={72}
            startFrame={0}
            sprite={`./assets/bg01.png`}
            shouldAnimate={isRunning}
          />
        </Flex>

        <Box
          position="relative"
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bg="#4ED4DD"
        >
          {loadDuckImage ? (
            <Center
              top={"-50%"}
              right={"-0%"}
              position="absolute"
              w="full"
              h="full"
            >
              <Spinner size="xl" speed="0.25s" />
            </Center>
          ) : (
            <Box
              id="my-duck"
              top="-25vh"
              left="3vw"
              position="absolute"
              overflow="hidden"
              onClick={async () => {
                if (isAutoPlayMode.current) {
                  setIsAutoPlayMode(false);
                }

                if (currentEnergyRef.current > 1) {
                  setIsRunning(true);
                  const resp = await APIClient.tap({ userToken });
                  if (resp?.isAuto) {
                    await fetchUser();
                  }

                  if (resp.energy < 2950) {
                    console.log("out of energy ...");
                    setIsRunning(false);
                    // return;
                  }
                  if (animationTimeoutRef.current) {
                    setIsRunning(false);
                    clearTimeout(animationTimeoutRef.current);
                  }
                  setIsRunning(true);

                  setAnimation(animateMetaData[1]?.running);

                  if (resp?.point) {
                    setCurrentPoint(resp?.point);
                    setCurrentEnergy(resp?.energy);
                    updateUserInfo((prev) => ({
                      ...prev,
                      last_update: resp?.data?.last_update,
                    }));
                  }
                  animationTimeoutRef.current = setTimeout(() => {
                    if (!isAutoPlayMode.current) {
                      setIsRunning(false);
                      setAnimation(animateMetaData[1]?.idle);
                    }
                  }, 1375);
                } else toast("Not enough energy");
              }}
            >
              <Square
                size="370px"
                style={{
                  userSelect: "none",
                  userDrag: "none",
                  draggable: false,
                  pointerEvents: "none",
                  WebkitUserSelect: "none",
                  WebkitTouchCallout: "none",
                }}
                overflow="hidden"
                display={isRunning ? "flex" : "none"}
              >
                <SpriteAnimator
                  style={{
                    userSelect: "none",
                    userDrag: "none",
                    draggable: false,
                    pointerEvents: "none",
                    WebkitUserSelect: "none",
                    WebkitTouchCallout: "none",
                  }}
                  // scale={1.25}
                  scale={(796 / stableSize.height) * (512 / 410)}
                  loop={true}
                  width={512}
                  height={512}
                  fps={24}
                  // JUMP
                  startFrame={2}
                  frameCount={33}
                  shouldAnimate={true}
                  sprite={duckUrl}
                />
              </Square>
              <Square
                size="370px"
                style={{
                  userSelect: "none",
                  userDrag: "none",
                  draggable: "false",
                  pointerEvents: "none",
                  WebkitUserSelect: "none",
                  WebkitTouchCallout: "none",
                }}
                overflow="hidden"
                display={!isRunning ? "flex" : "none"}
              >
                <SpriteAnimator
                  style={{
                    userSelect: "none",
                    userDrag: "none",
                    draggable: false,
                  }}
                  // scale={1.25}
                  scale={(796 / stableSize.height) * (512 / 410)}
                  loop={true}
                  width={512}
                  height={512}
                  fps={24}
                  // IDLE
                  startFrame={0}
                  frameCount={1}
                  shouldAnimate={true}
                  sprite={duckUrl}
                />
              </Square>
            </Box>
          )}

          {/* mouse click animation */}
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "50%",
              pointerEvents: "none",
              userSelect: "none",
              WebkitUserSelect: "none",
              WebkitTouchCallout: "none",
            }}
          >
            {animations.map((animation) => (
              <Box
                key={animation.id}
                style={{
                  position: "absolute",
                  left: animation.x - 10,
                  top: animation.y - 20,
                  fontSize: "32px",
                  fontWeight: "bold",
                  color: "#FFF",
                  animation: "floatUp 1s ease-out",
                  opacity: 0,
                }}
              >
                +{roundDown(1 * (userInfo?.mul_rate || 1), 2)}
              </Box>
            ))}
            <style>{`
            * {
              pointerEvents: "none",
              userSelect: "none",
              WebkitUserSelect: "none",
              WebkitTouchCallout: "none",
              pointer-events: none;
              user-select: none;
              -webkit-user-select: none;
              -webkit-touch-callout: none;
            }

            @keyframes floatUp {
              0% {
                transform: translateY(0);
                opacity: 1;
              }
              100% {
                transform: translateY(-100px);
                opacity: 0;
              }
            }
          `}</style>
          </div>
        </Box>
        <Flex
          pb="3vh"
          bg="#4ED4DD"
          zIndex={1}
          position="relative"
          justifyContent="center"
        >
          <Box
            zIndex={1}
            mx="7px"
            w="22vw"
            h="25vw"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            background="#366CB6"
            borderRadius="20px"
            border="2px solid rgba(26, 6, 179, 0.20)"
            // boxShadow="0px -10px 30px 0px #1A06B3 inset, 0px 10px 20px 0px rgba(0, 0, 0, 0.20)"
            sx={{
              transition: "all 0.2s ease-in-out",
            }}
            _active={{
              opacity: 0.8,
            }}
            onClick={() => history.push("/score")}
          >
            <MultipleIcon w="11vw" h="10vw" />
            <Flex mt="10px">
              <Text
                userSelect="none"
                sx={{
                  color: "#4ED4DD",
                  textAlign: "center",
                  textShadow: "0px 3px 3px #162B8A",
                  fontSize: "0.9rem",
                  fontWeight: "700",
                  lineHeight: "normal",
                }}
              >
                +
              </Text>
              <Text
                userSelect="none"
                sx={{
                  color: "#FFF",
                  textAlign: "center",
                  textShadow: "0px 3px 3px #162B8A",
                  fontSize: "0.9rem",
                  fontWeight: "700",
                  lineHeight: "normal",
                }}
              >
                {roundDown((+userInfo?.mul_rate - 1) * 100, 2)}%
              </Text>
            </Flex>
          </Box>
          <Box
            zIndex={1}
            mx="7px"
            w="22vw"
            h="25vw"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            background="#366CB6"
            borderRadius="20px"
            border="2px solid rgba(26, 6, 179, 0.20)"
            // boxShadow="0px -10px 30px 0px #1A06B3 inset, 0px 10px 20px 0px rgba(0, 0, 0, 0.20)"
            onClick={() => history.push("/boost")}
          >
            <BoostIcon w="11vw" h="8vw" />
            <Box sx={{ display: "flex" }}>
              <Text
                userSelect="none"
                sx={{
                  color: "#4ED4DD",
                  textAlign: "center",
                  textShadow: "0px 3px 3px #162B8A",
                  fontSize: "2rem",
                  fontWeight: "700",
                  lineHeight: "normal",
                }}
              >
                x
              </Text>
              <Text
                userSelect="none"
                sx={{
                  color: "#FFF",
                  textAlign: "center",
                  textShadow: "0px 3px 3px #162B8A",
                  fontSize: "2rem",
                  fontWeight: "700",
                  lineHeight: "normal",
                }}
              >
                5
              </Text>
            </Box>
          </Box>
          <AutoplayButton
            setIsAutoPlayMode={setIsAutoPlayMode}
            setIsRunning={setIsRunning}
            isRunning={isRunning}
          />
        </Flex>
      </Box>
    </>
  );
};

export default TelegramUserData;

import { Box, Button, Input, Text, Textarea } from "@chakra-ui/react";
import { APIClient } from "api";
import { useState } from "react";
import { useQuery } from "react-query";
import { format } from "utils/datetime";
const CampaignPage = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [selected, setSelected] = useState(null);
  const [addAirdropText, setAddAirdropText] = useState("");
  const [processedAirdropList, setProcessedAirdropList] = useState([]);
  const [newcampaignTitle, setNewCampaignTitle] = useState("");
  const [newcampaignDes, setNewCampaignDes] = useState("");
  const {} = useQuery("campaigns", async () => {
    const resp = await APIClient.getCampaigns();
    if (resp?.success == true) {
      setCampaigns(resp?.data);
    }
  });
  const processTextToList = (text) => {
    const lines = text.trim().split("\n");
    const result = lines.map((line) => {
      const [username, amount] = line.split(/\s+/);
      return {
        username: username,
        amount: parseInt(amount, 10),
      };
    });
    return result;
  };

  return (
    <Box
      sx={{
        p: "40px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Text
        sx={{
          fontSize: "24px",
          fontWeight: "bold",
        }}
      >
        CAMPAIGN
      </Text>
      <Box
        sx={{
          border: "1px solid red",
          p: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Input
          sx={{ border: "1px solid gray" }}
          placeholder="title"
          value={newcampaignTitle}
          onChange={(e) => setNewCampaignTitle(e.target.value)}
        />
        <Input
          sx={{ border: "1px solid gray", mt: "8px" }}
          placeholder="description"
          value={newcampaignDes}
          onChange={(e) => setNewCampaignDes(e.target.value)}
        />
        <Button size="sm" mt="8px">
          Create
        </Button>
      </Box>
      <Text sx={{ mt: "20px", fontWeight: "bold" }}>List campaign</Text>
      {campaigns?.map((e) => {
        const isSelected = selected == e?._id;
        return (
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                p: "20px",
                mt: "20px",
                border: isSelected ? "3px solid red" : "1px solid #ccc",
              }}
              onClick={() => setSelected(e?._id)}
            >
              <Text
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                {e?.title}
              </Text>
              <Text
                sx={{
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                {e?.description}
              </Text>
              <Text>Create at: {format(e?.created_at, "MMM Do YYYY")}</Text>
            </Box>
            {isSelected && (
              <Box sx={{ mt: "8px", display: "flex", flexDirection: "column" }}>
                <Textarea
                  value={addAirdropText}
                  placeholder="copy list from excel and paste"
                  onChange={(e) => setAddAirdropText(e.target.value)}
                />

                <Button
                  onClick={() => {
                    setProcessedAirdropList(processTextToList(addAirdropText));
                  }}
                  mt="8px"
                  size="sm"
                >
                  Process
                </Button>
                {processedAirdropList?.map((e) => (
                  <Text>
                    {e.username} - {e.amount}
                  </Text>
                ))}
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};
export default CampaignPage;

import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { APIClient } from "api";
import { useAppContext } from "contexts";
import { CiSaveDown2 } from "react-icons/ci";
import { formatTokenAmount } from "utils";
import toast from "react-hot-toast";
import { format } from "utils/datetime";
import { ToDoIcon } from "theme/components/icon";
import { DuckTokenIcon } from "theme/components/icon";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { delay } from "utils";

const BoostPage = () => {
  const { userToken } = useAppContext();
  // const [tabIndex, setTabIndex] = useState(0);
  const [transactions, setTransactions] = useState([]);

  const { isLoading, refetch } = useQuery("boost-tnxs", async () => {
    try {
      const resp = await APIClient.getBoostTnxs(userToken);
      if (resp?.success === true) {
        const data = resp?.transactions?.sort(
          (a, b) => parseInt(b.created_at) - parseInt(a.created_at)
        );

        setTransactions(data);
      }
    } catch (error) {
      console.log(error);
    }
  });

  const [tonConnectUI] = useTonConnectUI();

  async function handleBuyBoost(item) {
    try {
      // const body = beginCell()
      //   .storeUint(0, 32)
      //   .storeStringTail(item.comment)
      //   .endCell();

      // const payload = body.toBoc().toString("base64");

      // console.log({ comment: item.comment });
      // console.log({ payload: item.payload });

      const buyBoostTnx = {
        validUntil: Math.floor(Date.now() / 1000) + 120, // 120 sec
        messages: [
          {
            address: item.address,
            amount: item.price.toString(),
            payload: item.payload,
          },
        ],
      };
      console.log("buyBoostTnx", buyBoostTnx);
      const resp = await tonConnectUI.sendTransaction(buyBoostTnx);
      console.log("resp", resp);

      if (resp?.boc) {
        toast.promise(
          delay(30000).then(() => {
            console.log("load data...");
            refetch();
          }),
          {
            loading: "Please waiting for the TX finalize...",
            success: "Refetching...",
            error: "Something wrong !!!.",
          }
        );
      } else {
        toast.error("Transaction Failed");
      }
    } catch (error) {
      toast.error("Something wrong", error);
    }
  }
  return (
    <Box
      sx={{
        w: "100%",
        h: "100%",
        display: "flex",
        flexDirection: "column",
        p: "32px",
        justifyContent: "center",
        bg: "#4ED4DD",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          bg: "linear-gradient(270deg, #FFFDA4 0%, #FFFED9 49.67%, #FFFDA4 100%)",
          borderRadius: "40px",
        }}
      >
        <Box
          h="6vh"
          sx={{
            borderRadius: "40px",
            background: "#00006D",
            border: "2px solid gray",
            boxShadow:
              "0px -10px 30px 0px #1A06B3 inset, 0px 10px 20px 0px rgba(0, 0, 0, 0.20)",
            display: "flex",
            w: "calc(100% + 20px)",
            marginLeft: "-10px",
            marginTop: "-10px",
          }}
        >
          {[
            {
              icon: <ToDoIcon w="3vh" />,
              name: "Boost Center",
            },
          ].map((e, index) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  borderRadius: "40px",
                  transition: "all 0.2s ease-in-out",
                }}
                // onClick={() => {
                //   setTabIndex(index);
                // }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "center",
                    borderRadius: "40px",
                    p: "10px",
                    transition: "all 0.2s ease-in-out",
                  }}
                >
                  {e?.icon}
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#FFF",
                      textShadow: "0px 3px 3px #162B8A",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "normal",
                    }}
                  >
                    {e?.name}
                  </Text>
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box
          maxH="55vh"
          overflowY="scroll"
          sx={{
            flex: 1,
            p: "10px",
            overflowY: "scroll",
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </Box>
          ) : (
            <>
              <Box
                bg="white"
                borderRadius="20px"
                p="10px"
                mb="20px"
                textAlign="center"
              >
                <Text
                  userSelect="none"
                  sx={{
                    color: "#1A06B3",
                    textShadow: "0px 3px 3px rgba(0, 0, 0, 0.20)",
                    fontSize: "32px",
                    fontWeight: "700",
                    lineHeight: "normal",
                  }}
                >
                  Maximize
                </Text>
                <Text
                  userSelect="none"
                  sx={{
                    color: "#1A06B3",
                    textShadow: "0px 3px 3px rgba(0, 0, 0, 0.20)",
                    lineHeight: "normal",
                  }}
                >
                  Your points with a BOOST!
                </Text>
              </Box>

              <Flex mb="20px" justifyContent="space-evenly">
                {boostPackages?.map((item) => (
                  <Box
                    position="relative"
                    zIndex={1}
                    mx="7px"
                    w="28vw"
                    h="30vw"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    background="#366CB6"
                    borderRadius="20px"
                    border="2px solid rgba(26, 6, 179, 0.20)"
                  >
                    <Box
                      p="10px"
                      w="full"
                      h="full"
                      display="flex"
                      flexDir="column"
                      alignItems="center"
                      justifyContent="start"
                      onClick={() => handleBuyBoost(item)}
                    >
                      <Text
                        userSelect="none"
                        sx={{
                          color: "#FFF",
                          textAlign: "center",
                          textShadow: "0px 3px 3px #162B8A",
                          fontSize: "1.2rem",
                          fontWeight: "700",
                          lineHeight: "normal",
                        }}
                      >
                        {item.comment}
                      </Text>
                    </Box>
                    <Flex
                      h="32px"
                      borderRadius="20px"
                      w="full"
                      bg="#FFFDA4"
                      position="absolute"
                      bottom={"0px"}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Text
                        userSelect="none"
                        sx={{
                          color: "#1A06B3",
                          fontSize: "14px",
                          fontWeight: "700",
                          lineHeight: "normal",
                        }}
                      >
                        {item.price / Math.pow(10, 9)} TON
                      </Text>
                    </Flex>
                  </Box>
                ))}
              </Flex>

              {/* ================ */}

              {transactions?.map((tx, index) => {
                return (
                  <BoostTnxTag
                    tx={tx}
                    refetchTask={refetch}
                    key={`${tx?.hash}-${index}`}
                  />
                );
              })}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BoostPage;

const BoostTnxTag = ({ tx, refetchTask }) => {
  const { userToken, fetchUser } = useAppContext();
  const claimTransactionReward = async () => {
    try {
      const hash = tx?.hash;
      console.log({ hash });
      const resp = await APIClient.claimBoostTnxs(userToken, hash);
      if (resp?.success === true) {
        toast.success("Claimed successfully! Your point is updated");
        fetchUser();
        refetchTask();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Something wrong");
      console.log("error", error);
    }
  };
  const { isLoading, mutate } = useMutation(async () => {
    return await claimTransactionReward();
  });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        px: "10px",
        py: "4px",
        borderRadius: "40px",
        border: "2px solid rgba(26, 6, 179, 0.40)",
        background:
          "linear-gradient(180deg, rgba(0, 0, 103, 0.10) 0%, rgba(26, 6, 179, 0.00) 100%)",
        mb: "10px",
        fontSize: "14px",
        fontWeight: "700",
      }}
      key={tx?.hash}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          gap: "6px",
          fontSize: "12px",
          userSelect: "none",
        }}
      >
        <CiSaveDown2 size={24} />
        <Box>
          {tx?.comment} | {formatTokenAmount(tx?.value)}TON
          <Text
            sx={{
              fontSize: "12px",
              color: "gray",
            }}
          >
            {format(parseInt(tx?.created_at * 1000), "YYYY-MMM-DD HH:mm")}
          </Text>
        </Box>
      </Box>

      <Button
        px="3px"
        size="sm"
        gap="3px"
        isLoading={isLoading}
        onClick={() => mutate()}
        isDisabled={!!tx?.isDoneClaim}
        _hover={{ color: "gray" }}
      >
        {tx?.isDoneClaim ? (
          "Claimed"
        ) : (
          <>
            Claim
            <DuckTokenIcon width="18px" height="18px" />
          </>
        )}
      </Button>
    </Box>
  );
};

const boostPackages = [
  {
    comment: "Energy Top Up",
    price: 1000000,
    address: "UQDdQlP8_uQb7Fjueyfvc7j1jG2ck8AWO36g-3n42nW3mvkx",
    payload: "te6cckEBAQEAEwAAIgAAAABFbmVyZ3kgVG9wIFVwPNwU9A==",
  },
  {
    comment: "Boost 100% in 1day",
    price: 1000000,
    address: "UQAZdqArW-8ZBpzuQG-g2c7ak6Bn7WTrXrbf1n81EUfWgVFR",
    payload: "te6cckEBAQEAGAAALAAAAABCb29zdCAxMDAlIGluIDFkYXnxO8WI",
  },
];

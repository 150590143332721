import {
  background,
  Box,
  Button,
  Flex,
  Input,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { APIClient } from "api";
import { useAppContext } from "contexts";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { roundDown } from "utils";
import { formatNumDynDecimal } from "utils";
import { getName } from "utils";

import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";

const MenuAdminPage = () => {
  const { userToken } = useAppContext();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useState(null);

  return (
    <Box
      sx={{
        p: "40px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Button size="sm" onClick={() => history.push("/admin/status")}>
          App Status
        </Button>
        <Button size="sm" onClick={() => history.push("/admin/leaderboard")}>
          Ranking
        </Button>
        <Button size="sm" onClick={() => history.push("/admin/campaign")}>
          Campaign
        </Button>
      </Box>
      <Box
        sx={{
          mt: "16px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Input
          placeholder="ID"
          size="sm"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
        <Button
          sx={{ mt: "8px" }}
          size="sm"
          onClick={async () => {
            try {
              const resp = await APIClient.getUserAdmin(userToken, search);
              if (resp?.success == true) setUserData(resp?.data);
            } catch (error) {
              toast.error("Error");
            }
          }}
        >
          Search
        </Button>
        {userData && (
          <Box>
            <SimpleGrid columns={2}>
              <Text>ID</Text>
              <Text>{userData?.id}</Text>
              <Text>name</Text>
              <Text> {getName(userData)}</Text>
              <Text>Username</Text>
              <Text>{userData?.username}</Text>
              <Text>point</Text>
              <Text>
                {formatNumDynDecimal(roundDown(userData?.point, 2), 2)}
              </Text>
              <Text>ref point</Text>
              <Text>
                {formatNumDynDecimal(roundDown(userData?.refer_point, 2), 2)}
              </Text>
              <Text>ref count</Text>
              <Text>
                {formatNumDynDecimal(roundDown(userData?.refer_count, 2), 2)}
              </Text>
              <Text>age</Text>
              <Text>{userData?.age}</Text>
              <Text>mulrate</Text>
              <Text>{userData?.mul_rate}</Text>
            </SimpleGrid>
          </Box>
        )}
      </Box>
      <LineChart />
    </Box>
  );
};

export default MenuAdminPage;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

// { value: 120, label: "2h" },
// { value: 720, label: "12h" },
// { value: 1440, label: "1d" },
// { value: 4320, label: "3d" },
// { value: 10080, label: "1w" },
// { value: 20160, label: "2w" },
// { value: 40320, label: "4w" },

const LineChart = () => {
  const [minutes, setMinutes] = useState(10080);

  const [barChartData, setBarChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    (async () => {
      let data = await APIClient.fetchAnalyticsData({ minutes: minutes });

      if (!data[0]) return;

      data = filterLastItemPerDay(data);

      const labels = data.map((item) => item.timestamp);

      const barChartData = [
        {
          label: "Total Users",
          data: data.map((item) => item.total.totalUsers),
          backgroundColor: "blue",
        },
        {
          label: "Total Users With Points",
          data: data.map((item) => item.total.totalUsersWithPoints),
          backgroundColor: "rgb(255, 99, 132)",
        },
        // ...data[0]?.channels?.map((channel, index) => ({
        //   label: `Id ${channel.id} - Total Users`,
        //   data: data.map((item) => item.channels[index].totalUsers),
        //   backgroundColor: `rgb(${Math.random() * 255}, ${
        //     Math.random() * 255
        //   }, ${Math.random() * 255})`,
        // })),

        // ...data[0]?.channels?.map((channel, index) => ({
        //   label: `Id ${channel.id} - Total Users With Points`,
        //   data: data.map((item) => item.channels[index].totalUsersWithPoints),
        //   backgroundColor: `rgb(${Math.random() * 255}, ${
        //     Math.random() * 255
        //   }, ${Math.random() * 255})`,
        //  })),
      ];

      setBarChartData({ labels, datasets: barChartData });
    })();
  }, [minutes]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const [dauChartData, setDauChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    (async () => {
      let data = await APIClient.fetchDauData({ minutes });
      console.log({ dauData: data });
      if (!data[0]) return;

      const labels = data.map((item) => item?.timestamp?.split("T")?.[0]);
      console.log("labels", labels);
      console.log("data[0]", data[0]);
      const barChartData = [
        {
          label: "Total Users",
          data: data.map((item) => item.total.totalUsers),
          backgroundColor: "blue",
        },
        {
          label: "Old Users",
          data: data.map((item) => item.total.oldUsers),
          backgroundColor: "rgb(255, 99, 132)",
        },
      ];
      console.log("barChartData", barChartData);

      setDauChartData({ labels, datasets: barChartData });
    })();
  }, [minutes]);

  return (
    <>
      <Flex m="2">
        <Text>Choose time range</Text>
        {[
          // { value: 120, label: "2h" },
          // { value: 720, label: "12h" },
          // { value: 1440, label: "1d" },
          // { value: 4320, label: "3d" },
          { value: 10080, label: "1 week" },
          { value: 20160, label: "2 weeks" },
          { value: 40320, label: "4 weeks" },
          { value: 80640, label: "8 weeks" },
        ]?.map(({ label, value }) => (
          <Button
            mx="4px"
            size="xs"
            onClick={() => setMinutes(value)}
            background={value === minutes ? "blue" : "white"}
            color={value === minutes ? "white" : "lightgrey"}
          >
            {label}
          </Button>
        ))}
      </Flex>
      <Flex p="4" w="full">
        <Box w="1200px">
          <Text>User Count Bar Chart</Text>
          <Bar data={barChartData} options={options} />
        </Box>
        <Box w="1200px">
          <Text>Daily Active Users Chart</Text>
          <Bar data={dauChartData} options={options} />
        </Box>
      </Flex>
    </>
  );
};

function filterLastItemPerDay(data) {
  const groupedByDay = data.reduce((acc, item) => {
    const date = new Date(item.timestamp).toISOString().split("T")[0];
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(item);
    return acc;
  }, {});

  let result = Object.values(groupedByDay).map((dayItems) => {
    return dayItems.reduce((latest, current) => {
      return new Date(current.timestamp) > new Date(latest.timestamp)
        ? current
        : latest;
    });
  });

  result = result.map((item) => ({
    ...item,
    timestamp: item.timestamp.split("T")[0],
  }));

  return result.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
}
